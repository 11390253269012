import Content from './components/Content';
import Banner from './components/Banner';
import Wrapper from './components/Wrapper';
import Sidebar from './components/Sidebar';
import Container from './components/Container';
import Breadcrumbs from './pages/breadcrumbs/Breadcrumbs'
import CardContacts from './pages/contacts/CardContacts';
import Pagination from './components/Pagination';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

function App() {


  return (
    <Router>
      <Content>
        <Banner />
        <Wrapper>
          <Sidebar />
          <Container>
            <Breadcrumbs />
            <Routes>
              <Route path="/contacts" element={<CardContacts />} />
              <Route path="*" element={<Navigate to="/contacts" />} />
            </Routes>
            <Pagination />
            <Footer />
          </Container>
        </Wrapper>
      </Content>
    </Router>
  )
}

export default App;
