function Banner() {
    return(
           <div className='ba-sidebar ba-pd-r-4 ba-jc-fs ba-flex ba-mg-r-4'>
                    <ul className='ba-nav'>
                        <li className='ba-nav-item'>
                            <a href='#' className='ba-link ba-is-active'>
                                <builderall-icon code='8236' />
                                <span className='ba-title'>Dashboard</span>
                            </a>
                        </li>
                    </ul>
                    <div className="ba-grid ba-gp-1 ba-ai-end ba-mg-t-4 ba-pd-t-4">
                        <div className='ba-overview'>
                            <div className='ba-video-thumb'>
                                <img src='https://placehold.co/159x107/000000/AAAAAA' alt='Overview Tools Name' />	
                                <button className='ba-button'>
                                    <builderall-icon code='e6e4' />
                                </button>
                            </div>
                            <span className='ba-title'>Overview Tools Name </span>
                            <span className='ba-description'>Here we can insert a text with a written 3-line rule that better justifies the concept of the tool in use and not exceed.</span>
                            <button className='ba-btn ba-sm ba-blue ba-w-100'>
                                More Info
                                <builderall-icon code='c6a4' />
                            </button>
                        </div>
                        <div className='ba-bottom'>
                            <ul className='ba-bottom-list'>
                                <li>   
                                    <builderall-help />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>     
    )
}

export default Banner