import { createPortal } from 'react-dom'
import { useState, useEffect } from 'react'

import './ModalContact.css'
import SelectCodigo from './SelectCodigo'
import Input from './Input'
import ButtonSave from './ButtonSave'

function ModalContact({ isOpen, onClose, handleSubmit, contactData }) {

    const [codigo, setCodigo] = useState([])

    useEffect(() => {
        fetch((process.env.REACT_APP_API + "/codigo"), {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((resp) => resp.json())
            .then((data) => {
                setCodigo(data)
            })
            .catch((err) => console.log(err))
    }, [])

    const [contact, setContact] = useState(contactData || {})

    const submit = (e) => {
        e.preventDefault()
        console.log(contact)
        handleSubmit(contact)
    }

    function handleChange(e) {
        setContact({ ...contact, [e.target.name]: e.target.value })
    }

    function handleCodigo(e) {
        setContact({
            ...contact, codigo: {
                id: e.target.value,
                name: e.target.options[e.target.selectedIndex].text,
            },
        })
    }


    if (isOpen) {
        return createPortal(
            <div className="modal_container">
                <div className="ba-flex ba-gp-1 ba-jc-center ba-mg-x-4">
                    <div className='ba-card ba-mg-y-4'>
                        <div className='ba-top ba-w-100 ba-flex'>
                            <span className='ba-title ba-w-100'>
                                <div className='ba-w-100 ba-mg-1'>
                                    <h6 className="ba-color-blue1">CREATE A NEW CONTACT</h6>
                                </div>
                            </span>
                        </div>
                        <form className="ba-grid" onSubmit={submit}>
                            <div className="ba-grid  ba-mg-x-2">
                                <div className='ba-box-input    '>
                                    <Input
                                        type="text"
                                        text="NAME"
                                        name="name"
                                        className="ba-input ba-lg"
                                        handleOnChange={handleChange}
                                        value={contact.name ? contact.name : ''}
                                    />

                                    <div className='ba-info'>
                                        <div className='ba-info-left'>
                                            <label className='ba-label ba-montserrat-600' htmlFor='input_id'><p>PHONE NUMBER</p></label>
                                        </div>
                                    </div>
                                    <div className="ba-flex">
                                        <SelectCodigo
                                            name="codigo_id"
                                            options={codigo}
                                            handleOnChange={handleCodigo}
                                            value={contact.codigo ? contact.codigo.id : ''}
                                        />
                                        <Input
                                            type="number"
                                            name="number"
                                            className="ba-input ba-lg"
                                            handleOnChange={handleChange}
                                            value={contact.number ? contact.number : ''}
                                        />
                                    </div>
                                    <Input
                                        type="text"
                                        text="EMAIL"
                                        name="email"
                                        className="ba-input ba-lg"
                                        handleOnChange={handleChange}
                                        value={contact.email ? contact.email : ''}
                                    />
                                </div>
                            </div>
                            <div className='ba-top ba-w-100 ba-flex'>
                                <span className='ba-w-100'>
                                    <div className='ba-w-100'>
                                        <h6 className="ba-color-blue1 ba-mg-1">PERSONAL CUSTOM FIELDS</h6>
                                    </div>
                                </span>
                            </div>
                            <div className="ba-w-100 ba-flex">
                                <div className="ba-grid ba-w-100 ba-mg-x-2">
                                    <div className="ba-flex ba-w-100 ba-jc-sb">
                                        <Input
                                            type="text"
                                            text="FIELD NAME"
                                            name="field-name"
                                            className="ba-input ba-lg"
                                        />
                                        <Input
                                            type="text"
                                            text="VALUES"
                                            name="values"
                                            className="ba-input ba-lg"
                                        />
                                        <div className="ba-flex ba-ai-center ba-mg-t-3 ba-mg-r-1 ba-mg-l-1 ba-jc-fe">
                                            <button className='ba-btn ba-blue ba-xl'>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='ba-top ba-w-100 ba-flex'>
                                <span className='ba-w-100'>
                                    <div className='ba-w-100'>
                                        <h6 className="ba-color-blue1 ba-mg-1">ADD CUSTOM FIELD FOR ALL CONTACTS</h6>
                                    </div>
                                </span>
                            </div>
                            <div className="ba-w-100 ba-flex">
                                <div className="ba-grid ba-w-100 ba-mg-x-2">
                                    <div className="ba-flex ba-w-100 ba-jc-sb">
                                        <Input
                                            type="text"
                                            text="FIELD NAME"
                                            name="field-name"
                                            className="ba-input ba-lg"
                                        />
                                        <Input
                                            type="text"
                                            text="VALUES"
                                            name="values"
                                            className="ba-input ba-lg"
                                        />
                                        <div className="ba-flex ba-ai-center ba-mg-t-3 ba-mg-r-1 ba-mg-l-1 ba-jc-fe">
                                            <button className='ba-btn ba-blue ba-xl'>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='ba-top ba-flex'>
                                <div className='ba-box-items ba-flex ba-jc-fe ba-w-100 ba-mg-t-1'>
                                    <div className="ba-mg-b-1 ba-mg-r-1">
                                        <ButtonSave text="SAVE" />
                                    </div>
                                    <div className="ba-mg-b-1 ba-mg-r-1">
                                        <button className='ba-btn ba-bg-light7 ba-color-dark5 ba-xl' onClick={onClose}>
                                            CANCEL
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>, document.body
        )
    }
    return null
}

export default ModalContact