import styles from './IconsHover.module.css'

function Pagination() {
    return (
        <div className='ba-card ba-w-100 ba-flex ba-jc-sa'>
            <div className="ba-w-100 ba-flex">
                <nav className='ba-pagination ba-lg'>
                    <ul className='ba-list'>
                        <div className="ba-flex ba-jc-fs ba-mg-r-4 ba-pd-r-4">
                            <div className="ba-flex ba-mg-r-1">
                                <a className='ba-next' href='#'>
                                    <span className={styles.builderall_icon}>
                                        <builderall-icon code='arrow-left' />
                                    </span>
                                </a>
                            </div>
                            <div className="ba-flex ba-gp-1 ba-ai-center">
                                <h6 >PREVIOUS</h6>
                            </div>
                        </div>
                        <div className="ba-flex ba-gp-1 ba-jc-center ">
                            <li className='ba-nav-item'>
                                <a className='ba-link ba-is-active' href='#'>1</a>
                            </li>
                            <li className='ba-nav-item'>
                                <a className='ba-link' href='#'>2</a>
                            </li>
                            <li className='ba-nav-item'>
                                <a className='ba-link' href='#'>3</a>
                            </li>
                            ...
                            <li className='ba-nav-item'>
                                <a className='ba-link' href='#'>8</a>
                            </li>
                            <li className='ba-nav-item'>
                                <a className='ba-link' href='#'>9</a>
                            </li>
                            <li className='ba-nav-item'>
                                <a className='ba-link' href='#'>10</a>
                            </li>
                        </div>
                        <div className="ba-flex ba-jc-fe ba-mg-l-3 ba-pd-l-4 ba-mg-r-1">
                            <div className="ba-flex ba-gp-1 ba-ai-center">
                                <h6 >NEXT</h6>
                            </div>
                            <div className="ba-flex ba-mg-l-1">
                                <a className='ba-next' href='#'>
                                    <span className={styles.builderall_icon}>
                                        <builderall-icon code='arrow-right' />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Pagination