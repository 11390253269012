import styles from './Checkbox.module.css'
import TrTable from './contact/TrTable'

function TableContacts() {
    return (
        <table className='ba-table ba-md ba-w-100'>
            <thead>
                <tr>
                    <th>
                        <div className="ba-flex">
                            <div className="ba-flex ba-ai-center ba-mg-r-1">
                                <label className='ba-switch'>
                                    <input type='checkbox' className={styles.input_checkbox} />
                                </label>
                            </div>
                            <div className="ba-flex">
                                <p className="ba-montserrat-600">Name</p>
                            </div>
                        </div>
                    </th>
                    <th><p className="ba-flex ba-pd-r-4 ba-gp-1">Status</p></th>
                    <th><p className="ba-flex ba-pd-r-4 ba-gp-1">Subscribe</p></th>
                    <th><p className="ba-flex ba-pd-r-4 ba-gp-1">Tags</p></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <TrTable />
            </tbody>
        </table>
    )
}

export default TableContacts