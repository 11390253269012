import { useState, useEffect } from 'react'

import DataContacts from './DataContacts'

function TrTable({ handleRemove, handleEdit, id }) {

    const [name, setName] = useState("");
    const [nameId, setNameId] = useState("");
    const [contact, setContact] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [showProjectForm, setShowProjectForm] = useState(false)

    useEffect(() => {

        const loadData = async () => {

            const res = await fetch(process.env.REACT_APP_API + "/contacts")
                .then((res) => res.json())
                .then((data) => data)
                .catch((err) => console.log(err));

            setContacts(res);
        };

        loadData();

    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const contact = {
            name,
            handleRemove,
            handleEdit,
        }

        await fetch(process.env.REACT_APP_API + "/contacts", {
            method: "POST",
            body: JSON.stringify(contact),
            headers: {
                "Content-Type": "application/json",
            },
        });

        setContacts((prevState) => [...prevState, contact]);


    };


    const removeContact = async (id) => {
        await fetch(process.env.REACT_APP_API + "/contacts/" + id, {
            method: "DELETE",
        });

        setContacts((prevState) => prevState.filter((contact) => contact.id !== id));
    }

    const showContact = async (e) => {
        e.preventDefault();

        const contact = {
            nameId,
        }

        await fetch(process.env.REACT_APP_API + "/contacts", {
            method: "POST",
            body: JSON.stringify(contact),
            headers: {
                "Content-Type": "application/json",
            },
        });

        setContacts((prevState) => [...prevState, contact]);
        setContacts((prevState) => prevState.filter((contact) => contact.id !== id));


    };

    /*function editContact(contact) {

        fetch(process.env.REACT_APP_API + "/contacts/" + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contact),
        })
            .then(resp => resp.json())
            .then((data) => {
                setContact(data)
                setShowProjectForm(false)
            })
            .catch(err => console.log(err))
    }
    function toggleProjectForm() {
        setShowProjectForm(!showProjectForm)
    }*/

    return (
        <>
            {contacts?.length > 0 &&
                contacts.map((contact) => (
                    <DataContacts
                        name={contact.name}
                        key={contact.id}
                        onSubmit={handleSubmit}
                        handleRemove={() => removeContact(contact.id)}
                        showNameContact={() => showContact(contact.id)}
                    />
                ))
            }
        </>

    )
}

export default TrTable