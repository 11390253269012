function Banner() {
    return (
        <builderall-banner
            title="Whatsapp Official"
            subtitle="All of your WhatsApp tools in one place."
            description="Use WhatsApp to automate messages similar to an email autoresponder, automate messages for a product launch, or create your own help desk system."
        ></builderall-banner>
    )
}

export default Banner