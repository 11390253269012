import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import TableContacts from './table-contacts/TableContacts'
import { ReactComponent as SearchIcon } from '../../assets/search.svg'
import ModalContact from './table-contacts/modal-contacts/ModalContact'

function CardContacts({ handleSubmit }) {

    const [openModal, setOpenModal] = useState(false)
    const navigate = useNavigate

    function createPost(contact) {

        fetch(process.env.REACT_APP_API + "/contacts", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contact),
        })
            .then((resp) => resp.json())
            .then((data) => {
                console.log(data)
                //redirect
                navigate('/contacts')
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <div className='ba-card  ba-w-100 ba-mg-y-1'>
                <div className="ba-top ba-w-100">
                    <div className="ba-title mb-title ba-flex ba-jc-fs ba-w-100">
                        <div><p>Contacts</p></div>
                        <div className="ba-flex ba-jc-fe ba-mg-l-4 ba-pd-l-4 ba-w-100">
                            <div className='ba-box-search-input ba-flex ba-jc-fe ba-mg-b-1 ba-mg-r-1'>
                                <input type='search' className='ba-input' placeholder='Search... ' />
                                <button className='ba-button'>
                                    <SearchIcon />
                                </button>
                            </div>
                            <div>
                                <button onClick={() => setOpenModal(true)} className='ba-btn ba-blue ba-xl ba-mg-b-1'>
                                    Create New Contact
                                </button>
                                <ModalContact handleSubmit={createPost} isOpen={openModal} onClose={() => setOpenModal(false)} />
                            </div>
                        </div>
                    </div>
                    <TableContacts />
                </div>
            </div>
        </>
    )
}

export default CardContacts