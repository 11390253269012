import { useState, useEffect } from 'react'

import styles from '../Checkbox.module.css'

import ButtonDelete from '../ButtonDelete'
import Active from './btn-status/Active'
import Inativo from './btn-status/Inativo'
import ModalEditContact from './btn-status/modal-edit-contact/ModalEditContact'

function DataContacts({ id, name, contact, handleEdit, handleRemove, showNameContact }) {

    const [openModal, setOpenModal] = useState(false)

    const [open, setOpen] = useState(true);

    const status = {
        done: open,
    };

    const handleClickFalse = () => {
        setOpen(false);
    };

    const handleClickTrue = () => {
        setOpen(true);
    };

    return (
        <tr>
            <td>
                <div className="ba-flex ba-mg-r-2">
                    <div className="ba-flex ba-ai-center ba-mg-l-1">
                        <label className='ba-switch'>
                            <input type='checkbox' className={styles.input_checkbox} />
                        </label>
                    </div>
                    <div className="ba-flex">
                        <p className="ba-montserrat-600">{name}</p>
                    </div>
                </div>
            </td>
            <td>

                {status.done ?
                    <button className={styles.btn} onClick={handleClickFalse}>
                        <Active />
                    </button>
                    :
                    <button className={styles.btn} onClick={handleClickTrue}>
                        <Inativo />
                    </button>
                }
            </td>
            <td><p className="ba-montserrat-200">5 days ago</p></td>
            <td className="">
                <button className='ba-btn ba-sm ba-bg-green1'></button>
            </td>
            <td>
                <div className="ba-flex">
                    <div>
                        <button onClick={() => setOpenModal(true)} className='ba-btn ba-btn-normal ba-md ba-rounded ba-mg-x-1'>
                            <builderall-icon code='edit' />
                        </button>
                        <ModalEditContact isOpen={openModal} onClose={() => setOpenModal(false)} showNameContact={showNameContact} />
                    </div>
                    <ButtonDelete
                        handleRemove={handleRemove}
                    />
                </div>
            </td>
        </tr>
    )
}

export default DataContacts 