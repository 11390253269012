import { createPortal } from 'react-dom'

import '../../../modal-contacts/ModalContact.css'

function ModalEditContact({ isOpen, onClose, nameId }) {

    if (isOpen) {
        return createPortal(
            <div className="modal_container">
                <div className="ba-flex ba-gp-1 ba-jc-center ba-mg-x-4">
                    <div className='ba-card ba-mg-y-4 ba-w-50 ba-gp-1 ba-ai-center'>
                        <div className='ba-flex ba-ai-start ba-w-100 ba-jc-fe'>
                            <button onClick={onClose} className='ba-btn ba-btn-close ba-sm ba-rounded'>
                                <builderall-icon code='64f7' />
                            </button>
                        </div>
                        <div className='ba-top ba-w-100'>
                            <div className="ba-flex ba-title">
                                <span className='ba-w-100 ba-mg-b-1'>
                                    <div className="ba-flex ba-ai-start ba-mg-l-1">
                                        <h6 className="ba-color-blue1 ba-montserrat-bolds">Shelly Turner</h6>
                                        <div className="ba-flex ba-gp-1 ba-ai-center">
                                            <button className='ba-btn ba-light ba-sm ba-rounded '>
                                                <builderall-icon code='edit' />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="ba-flex ba-w-100 ba-gp-4">
                                        <div className="ba-flex ba-jc-fs ba-mg-r-4 ba-mg-l-1">
                                            <div className="ba-grid">
                                                <div className="ba-flex">
                                                    <div className="ba-flex ba-gp-1 ba-ai-center ba-mg-r-1">
                                                        <button className='ba-btn ba-light ba-sm ba-rounded '>
                                                            <builderall-icon code='4f18' />
                                                        </button>
                                                    </div>
                                                    <div><p>Updated:</p></div>
                                                    <div><p className="ba-mg-l-1 ba-montserrat-200">2 hours ago</p></div>
                                                </div>
                                                <div className="ba-flex">
                                                    <div className="ba-flex ba-gp-1 ba-ai-center ba-mg-r-1">
                                                        <button className='ba-btn ba-light ba-sm ba-rounded '>
                                                            <builderall-icon code='9ee6' />
                                                        </button>
                                                    </div>
                                                    <div><p>Subscribed:</p></div>
                                                    <div><p className="ba-mg-l-1 ba-montserrat-200">Active</p></div>
                                                </div>
                                                <div className="ba-flex">
                                                    <div className="ba-flex ba-gp-1 ba-ai-center ba-mg-r-1">
                                                        <button className='ba-btn ba-light ba-sm ba-rounded '>
                                                            <builderall-icon code='fd08' />
                                                        </button>
                                                    </div>
                                                    <div><p>Time of Contact:</p></div>
                                                    <div><p className="ba-mg-l-1 ba-montserrat-200">Unknown</p></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ba-flex ba-jc-fe ba-mg-l-4">
                                            <div className="ba-grid">
                                                <div className="ba-grid">
                                                    <div className="ba-flex">
                                                        <p> Contact Tags:</p>
                                                        <a href="" className="ba-mg-l-1"><p className="ba-color-blue1 ba-montserrat-400">Add Tag</p></a>
                                                    </div>
                                                    <button className='ba-btn ba-sm ba-green'>
                                                        Lorem Ipsum
                                                    </button>
                                                </div>
                                                <div className="ba-grid">
                                                    <div className="ba-flex">
                                                        <p>Customizable Fields:</p>
                                                        <div className="ba-flex ba-gp-1 ba-ai-center">
                                                            <button className='ba-btn ba-light ba-sm ba-rounded '>
                                                                <builderall-icon code='edit' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <button className='ba-btn ba-sm ba-bg-hover-black19	'>
                                                        Lorem Ipsum
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className="ba-flex ba-gp-1 ba-jc-center ba-mg-y-1 ba-w-100">
                                <button className='ba-btn ba-xl ba-blue ba-w-50 ba-mg-x-4'>
                                    START A CHAT
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>, document.body
        )
    }
    return null
}

export default ModalEditContact