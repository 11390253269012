function Inativo() {
    return (
        <div className="ba-flex">
            <div className="ba-flex ba-gp-1 ba-ai-center ba-mg-r-1"><span className='ba-circle ba-red'></span></div>
            <div><p className="ba-montserrat-200 ba-flex ba-pd-r-3 ba-mg-r-3">Inativo</p></div>
        </div>

    )
}

export default Inativo