import styles from '../../components/IconsHover.module.css'

function Breadcrumbs() {
    return (
        <>
            <div className='ba-card-breadcrumb ba-card ba-flex ba-jc-fs ba-w-100'>
                <nav className='ba-breadcrumb'>
                    <button className='ba-btn ba-sm ba-rounded'>
                        <span className={styles.builderall_icon}>
                            <builderall-icon code='arrow-left'/>
                        </span>
                    </button>
                    <button className='ba-btn ba-sm ba-rounded'>
                        <span className={styles.builderall_icon}>
                            <builderall-icon code='arrow-right' />
                        </span>
                    </button>
                    <hr className='ba-line' />
                    <ol className='ba-breadcrumb-ol'>
                        <li className='ba-breadcrumb-item ba-main-item'>
                            <a href='#'>
                                <builderall-icon code='house' />
                            </a>
                        </li>
                        <li className='ba-breadcrumb-item'>
                            <a href='#'>Dashboard</a>
                        </li>
                        <li className='ba-breadcrumb-item'>
                            <a href='#'>Contacts</a>
                        </li>
                    </ol>
                </nav>
            </div>
        </>
    )
}

export default Breadcrumbs