function Input({ type, text, name, className, handleOnChange, value }) {
    return (
        <div className='ba-flex ba-box-input ba-mg-r-1 ba-w-100'>
            <div className='ba-info'>
                <div className='ba-info-left'>
                    <label className='ba-label ba-montserrat-600' htmlFor='input_id'><p>{text}</p></label>
                </div>
            </div>
            <input
                type={type}
                name={name}
                id='input_id'
                className={className}
                onChange={handleOnChange}
                value={value}
            />
        </div>
    )
}

export default Input