function SelectCodigo({ name, options, handleOnChange, value }) {

    return (
        <select className='ba-select ba-md ba-mg-r-1'
            name={name}
            id={name}
            onChange={handleOnChange}
            value={value || ''}
        >
            {options.map((option) => (
                <option value={option.id} key={option.id}>
                    {option.name}
                </option>
            ))}
        </select>
    )
}

export default SelectCodigo